// 全局默认中间件(路由守卫)
import userStore from '@/stores/user'

export default defineNuxtRouteMiddleware((to,from)=>{
    const useUser = userStore()
    if(useUser.token){
        console.log('已经登录');
        
    }else{
        console.log('未登录');
        abortNavigation()  //停止当前导航，可以使用error进行报错
        return navigateTo({path:"/login",query:{redirect:to.fullPath}}) //去登录页并传递目标路由
    }
})